const getEmail = () => {
	const rawAuth = localStorage.getItem("persist:authentication");
	if (!rawAuth) return null;
	const auth = JSON.parse(rawAuth);
	if (!auth.token) return null;
	const token: string = JSON.parse(auth.token);
	if (!token) return null;
	const userInfo = token.split(".")[1];
	if (!userInfo) return null;
	const decodedUserInfo = JSON.parse(atob(userInfo));
	if (!decodedUserInfo) return null;
	const email: string = decodedUserInfo.email;
	return email;
};
const allowedHostnames = [
	"client-dev.celerum.online",
	"client-uat.celerum.online",
	"client-acc.celerum.online",
	"client-pre.celerum.online",
	"dev-client.neau.uk",
	"uat-client.neau.uk",
	"acc-client.neau.uk",
	"localhost",
];
const allowedEmails = ["@celerum.co.uk", "chris@grampianco.com"];
export const canISeeNewPages = () => {
	if (allowedHostnames.includes(location.hostname)) return true;
	const email = getEmail();
	if (!email) return false;
	return allowedEmails.some((x) => email.toLowerCase().includes(x));
};
