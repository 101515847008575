import {
	fileSecondaryIcon,
	truckIcon,
} from "../../../../../../../../common/models/src/lib/constants/icon.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import {
	ABSENT,
	NOT_AVAILABLE,
} from "../../../../../../../../common/models/src/lib/constants/messages.constants";
import type { IClearCustomsLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import { CelerumAddressCard } from "../../../../../../../../common/ui/src/lib/components/celerum-cards/celerum-cards.component";
import { formatDate } from "../../../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "../../leg-accordion-content.module.scss";
import { TitleSection } from "../title-section/title-section.component";

export const ClearCustomsInformation = ({ leg }: { leg: IClearCustomsLeg }) => {
	const { ucr, mrn, t1, clearanceDate, clearanceLocation } = leg;

	return (
		<div className={styles.container}>
			<div className={styles.wrapper} style={{ alignItems: "flex-start" }}>
				<div
					className={styles.wrapper}
					style={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 1fr)",
						gap: "16px",
					}}
				>
					<TitleSection
						icon={truckIcon}
						title="Leg type"
						value="Clear Customs"
					/>
					<TitleSection
						icon={fileSecondaryIcon}
						title="UCR"
						value={ucr || ABSENT}
					/>
					<TitleSection
						icon={fileSecondaryIcon}
						title="MRN"
						value={mrn || ABSENT}
					/>
					<TitleSection
						icon={fileSecondaryIcon}
						title="T1"
						value={t1 || ABSENT}
					/>
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice"
						value={
							leg?.supplierInvoice !== undefined
								? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? ABSENT
								: ABSENT
						}
					/>
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice Number"
						value={
							leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
								? leg?.supplierInvoiceNumber
								: NOT_AVAILABLE
						}
					/>
					<TitleSection
						icon={truckIcon}
						title="Supplier Invoice Date"
						value={
							leg?.supplierInvoiceDate
								? formatDate(new Date(leg.supplierInvoiceDate))
								: NOT_AVAILABLE
						}
					/>
				</div>
				<div style={{ width: "40%" }}>
					<CelerumAddressCard
						title="Location for Clearance"
						name={clearanceLocation}
						address={clearanceLocation}
						date={
							clearanceDate
								? formatDate(new Date(clearanceDate))
								: NOT_AVAILABLE
						}
					/>
				</div>
			</div>
		</div>
	);
};
