import {
	INVOICE_FREQUENCY,
	INVOICE_SEND_METHOD,
	INVOICE_TYPE,
} from "../../../../../../common/models/src/lib/constants/invoice.constants";
import { NOT_AVAILABLE } from "../../../../../../common/models/src/lib/constants/messages.constants";
import { useAppSelector } from "../../../../../../common/stores/src/lib/utils";
import { CelerumLoader } from "../../../../../../common/ui/src/lib/components/celerum-loader/celerum-loader.components";
import styles from "./customer-container.module.scss";

export const CustomerContainer = () => {
	const { job, loading } = useAppSelector((state) => state.jobs);

	const renderItem = (...args: [string, string | number, Date?]) => {
		return (
			<div className={styles.content__item}>
				<span className={styles.content__item__title}>{args[0]}</span>
				<div className={styles.content__item__wrapper}>
					<span className={styles.content__item__value}>{args[1]}</span>
					{args[2] && (
						<span className={styles.content__item__value}>
							{args[2].toLocaleString()}
						</span>
					)}
				</div>
			</div>
		);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<span className={styles.header__title}>Customer Information</span>
			</div>
			{loading.job ? (
				<div className={styles.loader}>
					<CelerumLoader visible />
				</div>
			) : (
				<div className={styles.content}>
					{renderItem("Name", job?.customer?.name ?? NOT_AVAILABLE)}
					{renderItem(
						"Account Code",
						job?.customer?.accountCode ?? NOT_AVAILABLE,
					)}
					{renderItem("VAT Number", job?.customer?.vatNumber ?? NOT_AVAILABLE)}
					{renderItem(
						"Currency",
						job?.customer?.currency?.code ?? NOT_AVAILABLE,
					)}
					{renderItem("Address", job?.customer?.address ?? NOT_AVAILABLE)}
					{renderItem(
						"Primary Phone Number",
						job?.customer?.phones[0] ?? NOT_AVAILABLE,
					)}
					{renderItem(
						"Primary Email",
						job?.customer?.emails[0] ?? NOT_AVAILABLE,
					)}
					{renderItem(
						"Invoice Send Method",
						job?.customer?.invoiceSentMethod
							? INVOICE_SEND_METHOD[job.customer.invoiceSentMethod - 1] ??
									NOT_AVAILABLE
							: NOT_AVAILABLE,
					)}
					{renderItem(
						"Invoice Template",
						job?.customer?.invoiceTemplate ?? NOT_AVAILABLE,
					)}
					{renderItem(
						"Invoice Type",
						job?.customer?.invoiceType
							? INVOICE_TYPE[job.customer.invoiceType - 1] ?? NOT_AVAILABLE
							: NOT_AVAILABLE,
					)}
					{renderItem(
						"Invoice Frequency",
						job?.customer?.invoiceFrequency !== undefined
							? INVOICE_FREQUENCY[job.customer.invoiceFrequency] ??
									NOT_AVAILABLE
							: NOT_AVAILABLE,
					)}
					{renderItem(
						"Is PO Number Mandatory",
						job?.customer?.isPurchaseOrderNumberMandatory ? "Yes" : "No",
					)}
					{renderItem(
						"Is EU Customer",
						job?.customer?.isEuCustomer ? "Yes" : "No",
					)}
					{renderItem(
						"Merge documents in one file",
						job?.customer?.mergeAttachmentInOneFile ? "Yes" : "No",
					)}
				</div>
			)}
		</div>
	);
};
