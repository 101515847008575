import { useMemo } from "react";
import {
	boxIcon,
	truckIcon,
} from "../../../../../../../../common/models/src/lib/constants/icon.constants";
import { SUPPLIER_INVOICES } from "../../../../../../../../common/models/src/lib/constants/invoice.constants";
import { NOT_AVAILABLE } from "../../../../../../../../common/models/src/lib/constants/messages.constants";
import type { IStorageLeg } from "../../../../../../../../common/models/src/lib/interfaces/leg.interface";
import { formatDate } from "../../../../../../../../common/utils/src/lib/helpers/date.helpers";
import styles from "../../leg-accordion-content.module.scss";
import { TitleSection } from "../title-section/title-section.component";

export const StorageInformation = ({ leg }: { leg: IStorageLeg }) => {
	const { storageStartDate, storageEndDate } = leg;

	const numberOfDays = useMemo(
		() =>
			storageEndDate && storageStartDate
				? Math.ceil(
						(new Date(storageEndDate).getTime() -
							new Date(storageStartDate).getTime()) /
							(1000 * 60 * 60 * 24),
					)
				: Number.NaN,
		[storageStartDate, storageEndDate],
	);

	return (
		<div className={styles.container}>
			<div
				className={styles.wrapper}
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(3,1fr)",
					gap: "32px",
				}}
			>
				<TitleSection icon={truckIcon} title="Leg type" value="Storage" />
				<TitleSection
					icon={boxIcon}
					title="Storage Days"
					value={
						!Number.isNaN(numberOfDays)
							? numberOfDays.toString()
							: NOT_AVAILABLE
					}
				/>
				<TitleSection
					icon={boxIcon}
					title="Storage Start Time"
					value={
						storageStartDate
							? formatDate(new Date(storageStartDate))
							: NOT_AVAILABLE
					}
				/>
				<TitleSection
					icon={boxIcon}
					title="Storage End Time"
					value={
						storageEndDate
							? formatDate(new Date(storageEndDate))
							: NOT_AVAILABLE
					}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice"
					value={
						leg?.supplierInvoice !== undefined
							? SUPPLIER_INVOICES[leg.supplierInvoice]?.name ?? NOT_AVAILABLE
							: NOT_AVAILABLE
					}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice Number"
					value={
						leg?.supplierInvoiceNumber && leg.supplierInvoiceNumber !== ""
							? leg?.supplierInvoiceNumber
							: NOT_AVAILABLE
					}
				/>
				<TitleSection
					icon={truckIcon}
					title="Supplier Invoice Date"
					value={
						leg?.supplierInvoiceDate
							? formatDate(new Date(leg.supplierInvoiceDate))
							: NOT_AVAILABLE
					}
				/>
			</div>
		</div>
	);
};
